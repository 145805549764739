<script lang="ts" setup>
import imageCompression from "browser-image-compression";

const props = defineProps({
  onUpload: {
    type: Function as PropType<(url: string) => void>,
    required: true,
  },
});
const fileInput = ref<HTMLInputElement | null>(null);

const handleOpenInput = () => {
  fileInput.value?.click();
};

const state = ref({
  image: null as File | null,
});
const imagePreview = ref<string>("");

// TODO: Implement
// const validate = (file: File) => {
//   if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
//     // TODO: Set errors

//     return false;
//   }

//   return true;
// };

const reset = () => {
  state.value.image = null;
  imagePreview.value = "";
};
const compressOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

const toast = useToast();
const onFileChange = async (event: Event) => {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];
  if (!file) {
    return;
  }

  try {
    const compressedFile = await imageCompression(file, compressOptions);
    state.value.image = compressedFile;
    imagePreview.value = URL.createObjectURL(compressedFile);
    submit();
  } catch (e) {
    reset();
    toast.add({
      title: "Error",
      description: "Error al subir la imagen",
      icon: "i-ph-x-circle",
      color: "red",
    });
  }
};

const sanctumClient = useSanctumClient();
const { submit, inProgress } = useSubmit(
  () => {
    const formData = new FormData();
    formData.append("files[]", state.value.image as File);

    return sanctumClient("/files", {
      method: "POST",
      body: formData,
    });
  },
  {
    onSuccess: (response: FileResource[]) => {
      props.onUpload(response[0].path);
      toast.add({
        title: "Imagen subida",
        description: "La imagen se ha subido correctamente",
        icon: "i-ph-check-circle",
        color: "green",
      });
    },
    onError: () => {
      toast.add({
        title: "Error",
        description: "Error al subir la imagen",
        icon: "i-ph-x-circle",
        color: "red",
      });
    },
  },
);
</script>

<template>
  <div
    class="border rounded-lg border-dashed border-separate border-gray-400 w-full p-4 text-center"
  >
    <Suspense>
      <UIcon name="i-ph-image" class="h-12 w-12 text-gray-400" />
    </Suspense>
    <p class="text-gray-500">Arrastra y suelta una imagen aquí o</p>
    <Suspense>
      <UButton
        :loading="inProgress"
        color="primary"
        class="mt-2"
        size="sm"
        leading-icon="i-ph-upload-simple-bold"
        @click="handleOpenInput"
        >Sube una imagen</UButton
      >
    </Suspense>

    <input
      ref="fileInput"
      type="file"
      class="hidden"
      accept="image/*"
      @change="onFileChange"
    />
  </div>
</template>

<style></style>
